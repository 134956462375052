import React from 'react';
import FlipCameraIosIcon from '@material-ui/icons/FlipCameraIos';
import { IconButton } from '@material-ui/core';
import useFlipCameraToggle from '../../../hooks/useFlipCameraToggle/useFlipCameraToggle';

export default function FlipCameraButton() {
  const { flipCameraDisabled, toggleFacingMode, flipCameraSupported } = useFlipCameraToggle();

  return flipCameraSupported ? (
    <IconButton onClick={toggleFacingMode} disabled={flipCameraDisabled}>
      <FlipCameraIosIcon />
    </IconButton>
  ) : null;
}
