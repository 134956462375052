import React from 'react';

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';

import SettingsMenu from '../PreJoinScreens/DeviceSelectionScreen/SettingsMenu/SettingsMenu';
import VideoQualityButton from './VideoQualityButton';
import ToggleFullscreenButton from './ToggleFullScreenButton/ToggleFullScreenButton';
import FlipCameraButton from './FlipCameraButton/FlipCameraButton';

import { Grid, Hidden, Typography } from '@material-ui/core';
import { store } from '../../index';
import { observer } from 'mobx-react-lite';

export const TOOLBAR_HEIGHT = 64;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      backgroundColor: theme.palette.background.paper,
      maxHeight: TOOLBAR_HEIGHT,
      minHeight: TOOLBAR_HEIGHT,
      marginBottom: '10px',
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
    toolbar: {
      [theme.breakpoints.down('xs')]: {
        padding: 0,
      },
      maxHeight: TOOLBAR_HEIGHT,
      minHeight: TOOLBAR_HEIGHT,
    },
    rightButtonContainer: {
      display: 'flex',
      alignItems: 'center',
      marginLeft: 'auto',
    },
    form: {
      display: 'flex',
      flexWrap: 'wrap',
      alignItems: 'center',
    },
    loadingSpinner: {
      marginLeft: '1em',
    },
    joinButton: {
      margin: '1em',
    },
    displayName: {
      margin: '1.1em 0.6em',
      color: theme.palette.text.primary,
    },
    mobileButton: {
      padding: '0.8em 0',
      margin: 0,
    },
  })
);

function TopMenuBar() {
  const classes = useStyles();
  const renderFullScreenButton = /Android|Macintosh|Windows|Linux/.test(navigator.userAgent);

  return (
    <AppBar className={classes.container} position="static">
      <Toolbar className={classes.toolbar}>
        <Grid container direction="row" justifyContent="space-between" alignItems="center">
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {store.tenantLogo ? (
              <img src={store.tenantLogo} style={{ height: '40px', marginLeft: '5px' }} alt="Logo" />
            ) : null}
            <Typography className={classes.displayName}>
              {store.tenantProjectName ? store.tenantProjectName : null}
            </Typography>
          </div>
          <Hidden smDown>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <VideoQualityButton />
              <SettingsMenu mobileButtonClass={classes.mobileButton} />
              {renderFullScreenButton && <ToggleFullscreenButton />}
            </div>
          </Hidden>
          <Hidden smUp>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <VideoQualityButton />
              <FlipCameraButton />
              {renderFullScreenButton && <ToggleFullscreenButton />}
            </div>
          </Hidden>
        </Grid>
      </Toolbar>
    </AppBar>
  );
}

export default observer(TopMenuBar);
