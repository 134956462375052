export const BACKGROUND_FILTER_VIDEO_CONSTRAINTS: MediaStreamConstraints['video'] = {
  width: 640,
  height: 480,
  frameRate: 24,
};

export const DEFAULT_VIDEO_CONSTRAINTS: MediaStreamConstraints['video'] = {
  width: 1280,
  height: 720,
  frameRate: 24,
};

// These are used to store the selected media devices in localStorage
export const SELECTED_AUDIO_INPUT_KEY = 'TwilioVideoApp-selectedAudioInput';
export const SELECTED_AUDIO_OUTPUT_KEY = 'TwilioVideoApp-selectedAudioOutput';
export const SELECTED_VIDEO_INPUT_KEY = 'TwilioVideoApp-selectedVideoInput';

// This is used to store the current background settings in localStorage
export const SELECTED_BACKGROUND_SETTINGS_KEY = 'TwilioVideoApp-selectedBackgroundSettings';

export const GALLERY_VIEW_ASPECT_RATIO = 9 / 16; // 16:9
export const GALLERY_VIEW_MARGIN = 3;

export const STAGE = process.env.REACT_APP_STAGE;

const getRootUrl = (stage: any) => {
  if (stage === 'dev') return 'https://api.rooms.a2d24.dev/room';
  else if (stage === 'staging') return 'https://api.rooms-staging.netcare.digital/room';
  else if (stage === 'prod') return 'https://api.rooms.netcare.digital/room';

  // Default
  return 'http://localhost:8888/room';
};

const getWebsocketUrl = (stage: any) => {
  if (stage === 'dev') return 'wss://ws.rooms.a2d24.dev';
  else if (stage === 'staging') return 'wss://ws.rooms-staging.netcare.digital';
  else if (stage === 'prod') return 'wss://ws.rooms.netcare.digital';

  // Default
  return 'ws://localhost:7777';
};

export const API_ROOT_URL = getRootUrl(STAGE);
export const WEBSOCKET_URL = getWebsocketUrl(STAGE);
