import React, { useEffect, useState } from 'react';
import { Typography } from '@material-ui/core';
import { useComponentWillMount } from '../../hooks/useComponentWillMount';
import PreConsultActionButton from '../PreJoinScreens/ReadyComponent/Instructions/PreConsultActionButton';
import styled from '@material-ui/core/styles/styled';
import Container from '@material-ui/core/Container';
import { observer } from 'mobx-react-lite';
import { store } from '../../index';
import { getPermissions, getDevices } from '../../state/helpers';

const RequestMediaPermissions = () => {
  const [title, setTitle] = useState('Please Wait...');
  const [text, setText] = useState(false);
  const { permissionsStep } = store;

  useComponentWillMount(() => {
    getPermissions();
  });

  useEffect(() => {
    if (permissionsStep !== 'unknown') {
      setTitle('We need your permission!');
      setText(true);
    }
  }, [permissionsStep]);

  return (
    <ContainerDiv>
      <LightTitle variant="h4" align="center">
        {title}
      </LightTitle>
      <br />
      {text && <MainText>This application needs access to your camera and microphone for your consultation</MainText>}
      <br />
      {text && <MainText>Please follow the steps below:</MainText>}
      <br />
      {text && <MainText>1. Click on the 'Grant Permission' button below</MainText>}
      {text && <MainText>2. Click 'ALLOW/ACCEPT' on the pop-ups that appear</MainText>}
      <br />
      {text && (
        <PreConsultActionButton
          id="GivePermissionButton"
          type="submit"
          color="primary"
          variant="contained"
          onClick={() => {
            store.setRequestPermissions(false);
            getDevices();
          }}
        >
          Grant Permission
        </PreConsultActionButton>
      )}
      {text && <SubText>Still having trouble? Please check the camera and microphone settings on your device.</SubText>}
    </ContainerDiv>
  );
};

export default observer(RequestMediaPermissions);

const ContainerDiv = styled(Container)({
  width: '100%',
  height: '95vh',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '20px',
  boxSizing: 'border-box',
});

const LightTitle = styled(Typography)({
  fontWeight: 300,
  paddingTop: 15,
  paddingBottom: 15,
});

const MainText = styled(Typography)({
  maxWidth: '95%',
  textAlign: 'center',
});

const SubText = styled(Typography)({
  maxWidth: '95%',
  textAlign: 'center',
  fontSize: '16px',
  color: '#898989',
  marginTop: '20px',
});
