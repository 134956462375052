import React from 'react';
import { Button } from '@material-ui/core';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import { LocalVideoTrack } from 'twilio-video';
import { observer } from 'mobx-react-lite';
import { store } from '../../index';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    qualityDiv: {
      display: 'flex',
      flexDirection: 'column',
      color: 'black',
      alignItems: 'center',
      justifyContent: 'center',
      [theme.breakpoints.down('xs')]: {
        marginBottom: '12px',
        marginTop: '5px',
      },
    },
    buttonStyle: {
      width: '60px',
      [theme.breakpoints.down('xs')]: {
        paddingTop: 0,
        paddingBottom: 0,
      },
    },
  })
);

function VideoQualityButton() {
  const { room, localTracks, getLocalVideoTrack } = useVideoContext();

  const localVideoTrack = localTracks.find(track => track.kind === 'video') as LocalVideoTrack;
  const localVideoInputDeviceId = localVideoTrack?.mediaStreamTrack.getSettings().deviceId;
  const classes = useStyles();

  function replaceTrack(newDeviceId: string, newVideoQuality: string) {
    localVideoTrack?.stop();
    getLocalVideoTrack({ deviceId: { exact: newDeviceId } }, newVideoQuality).then(newTrack => {
      if (localVideoTrack) {
        const localTrackPublication = room?.localParticipant?.unpublishTrack(localVideoTrack);
        // TODO: remove when SDK implements this event. See: https://issues.corp.twilio.com/browse/JSDK-2592
        room?.localParticipant?.emit('trackUnpublished', localTrackPublication);
      }

      room?.localParticipant?.publishTrack(newTrack);
    });
  }

  const changeVideoQuality = () => {
    let newQuality;
    if (store.videoQuality === 'high') newQuality = 'medium';
    else if (store.videoQuality === 'medium') newQuality = 'low';
    else newQuality = 'high';
    store.setVideoQuality(newQuality);

    if (localVideoInputDeviceId) {
      replaceTrack(localVideoInputDeviceId, newQuality);
    }
  };

  if (!localVideoInputDeviceId || store.videoDisconnected) return null;

  return (
    <div className={classes.qualityDiv}>
      <div style={{ fontSize: '0.7em' }}>Set Data Usage:</div>
      <Button onClick={changeVideoQuality} variant={'outlined'} className={classes.buttonStyle}>
        {store.videoQuality === 'medium' ? 'MED' : store.videoQuality.toUpperCase()}
      </Button>
    </div>
  );
}

export default observer(VideoQualityButton);
