import React from 'react';

import { styled } from '@material-ui/core/styles';
import { Typography, Fade } from '@material-ui/core';

const PreJoinContiner = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyItems: 'center',
  width: '100%',
  height: '100%',
});

const LightTitle = styled(Typography)({
  paddingTop: 15,
  paddingBottom: 15,
});

export default (props: { room: any }) => {
  return (
    <Fade in={true} timeout={{ enter: 500, exit: 0 }}>
      <PreJoinContiner>
        <LightTitle variant="h4" align="center">
          Invalid Link
        </LightTitle>
        <Typography align="center">Your link is invalid or expired</Typography>
      </PreJoinContiner>
    </Fade>
  );
};
