import React from 'react';
import { styled, Theme } from '@material-ui/core/styles';

import MenuBar from './components/MenuBar/MenuBar';
import MobileTopMenuBar from './components/MobileTopMenuBar/MobileTopMenuBar';
import TopMenuBar from './components/MenuBar/TopMenuBar';
import PreJoinScreens from './components/PreJoinScreens/PreJoinScreens';
import ReconnectingNotification from './components/ReconnectingNotification/ReconnectingNotification';
import RecordingNotifications from './components/RecordingNotifications/RecordingNotifications';
import PostConsult from './components/PostConsult/PostConsult';
import Room from './components/Room/Room';

import useHeight from './hooks/useHeight/useHeight';
import useRoomState from './hooks/useRoomState/useRoomState';

import { store } from './index';
import { useAppState } from './state';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

const Container = styled('div')({
  display: 'grid',
  gridTemplateRows: '1fr auto',
});

const PreContainer = styled('div')({
  display: 'grid',
  gridTemplateRows: 'auto auto',
});

const Main = styled('main')(({ theme }: { theme: Theme }) => ({
  overflow: 'hidden',
  paddingBottom: `${theme.footerHeight}px`, // Leave some space for the footer
  background: 'black',
  [theme.breakpoints.down('sm')]: {
    paddingBottom: `${theme.mobileFooterHeight + theme.mobileTopBarHeight}px`, // Leave some space for the mobile header and footer
  },
}));

function App() {
  const roomState = useRoomState();
  const { user, getParticipantData } = useAppState();
  const { URLParticipantAuthentication } = useParams<{ URLParticipantAuthentication: string }>();

  // Here we would like the height of the main container to be the height of the viewport.
  // On some mobile browsers, 'height: 100vh' sets the height equal to that of the screen,
  // not the viewport. This looks bad when the mobile browsers location bar is open.
  // We will dynamically set the height with 'window.innerHeight', which means that this
  // will look good on mobile browsers even after the location bar opens or closes.
  const height = useHeight();

  React.useEffect(() => {
    store.setToken(URLParticipantAuthentication);

    getParticipantData(URLParticipantAuthentication).then(
      ({
        token,
        room_name,
        username,
        participant_type,
        room_status,
        logo,
        terms_and_conditions_link,
        project_name,
        features,
        feedback,
      }) => {
        store.setTwilioToken(token);
        store.setRoomName(room_name);
        store.setUserName(username);
        store.setFeatures(features);
        store.setRoomStatus(room_status);
        store.setTenantLogo(logo);
        store.setTenantTermsAndConditionsLink(terms_and_conditions_link);
        store.setTenantProjectName(project_name);
        store.setFeedback(feedback);
        store.setUserType(participant_type);
      }
    );

    store.requestSocketConnect(URLParticipantAuthentication);
  }, [URLParticipantAuthentication]);

  return (
    <>
      {roomState === 'disconnected' ? (
        !store.videoDisconnected ? (
          <PreContainer>
            <TopMenuBar />
            <MobileTopMenuBar />
            <PreJoinScreens />
          </PreContainer>
        ) : (
          <PreContainer>
            <TopMenuBar />
            <MobileTopMenuBar />
            <PostConsult />
          </PreContainer>
        )
      ) : (
        <Container style={{ height }}>
          <Main>
            <ReconnectingNotification />
            <RecordingNotifications />
            <TopMenuBar />
            <MobileTopMenuBar />
            <Room />
            <MenuBar />
          </Main>
        </Container>
      )}
    </>
  );
}

export default observer(App);
