import React, { useCallback, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Typography, Grid, Theme } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { styled, MuiThemeProvider, makeStyles } from '@material-ui/core/styles';
import 'survey-core/defaultV2.min.css';
import './surveyStyling.css';
import { Model } from 'survey-core';
import { Survey } from 'survey-react-ui';
import { surveyThemeJson } from './surveyTheme';
import theme from './theme';
import { store } from '../../index';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    padding: '15px 25px',
    margin: '1em',
    fontWeight: 400,
    width: '140px',
    fontSize: '16px',
    textTransform: 'none',
    backgroundColor: '#1d3443',
  },
}));

function Room() {
  const [surveyCompleted, setSurveyCompleted] = React.useState<boolean>(false);
  const { getAudioAndVideoTracks } = useVideoContext();
  const [mediaError, setMediaError] = useState<Error>();
  const survey = new Model(store.feedback);
  const classes = useStyles();

  useEffect(() => {
    if (!mediaError) {
      getAudioAndVideoTracks().catch(error => {
        console.log('Error acquiring local media:');
        console.dir(error);
        setMediaError(error);
      });
    }
  }, [getAudioAndVideoTracks, mediaError]);

  survey.applyTheme(surveyThemeJson);
  survey.completeText = 'Submit feedback';

  const surveyComplete = useCallback(sender => {
    store.saveSurveyFeedback(sender.data);
    setSurveyCompleted(true);
  }, []);
  survey.onComplete.add(surveyComplete);
  const handleRejoin = () => {
    store.setVideoDisconnected(false);
  };

  const handleCloseRoom = () => {
    store.closeRoom();
  };

  return (
    <MuiThemeProvider theme={theme}>
      <Container>
        <Typography variant="h1" component="h1" align="center">
          {store.room.room_status === 'ended' ? 'Room closed' : "You've left the room"}
        </Typography>
        <ButtonsContainer>
          <Grid container alignItems="center" justifyContent="center" wrap="nowrap">
            {store.room.room_status !== 'ended' && (
              <Button className={classes.button} color="primary" variant="contained" onClick={handleRejoin}>
                Rejoin
              </Button>
            )}
            {store.userType === 'host' && store.room.room_status !== 'ended' && (
              <Button className={classes.button} color="primary" variant="contained" onClick={handleCloseRoom}>
                Close room
              </Button>
            )}
          </Grid>
        </ButtonsContainer>
        {surveyCompleted ? (
          <Typography variant="h6" align="center" gutterBottom>
            Thank you for your feedback!
          </Typography>
        ) : (
          store.feedback?.elements?.length > 0 && <Survey model={survey} />
        )}
      </Container>
    </MuiThemeProvider>
  );
}

const Container = styled('div')(({ theme }) => ({
  height: 'calc(100vh - 75px)',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: '#fafafa',
  padding: '20px',
}));

const ButtonsContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  margin: '1em',
}));

export default observer(Room);
