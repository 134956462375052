import React from 'react';

import useSound from 'use-sound';

import { styled } from '@material-ui/core/styles';
import { Typography, Fade } from '@material-ui/core';
import PreConsultActionButton from './PreConsultActionButton';
import { store } from '../../../../index';

const PreJoinContiner = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyItems: 'center',
  width: '100%',
  height: '100%',
});

const LightTitle = styled(Typography)({
  paddingTop: 15,
  paddingBottom: 15,
});

const handleStart = () => {
  store.startRoom();
};

export default (props: { room: any }) => {
  const [play] = useSound('/sound/join.mp3');
  const [text, setText] = React.useState<string>('');

  React.useEffect(() => {
    if (props.room.guest_ready) {
      play();
      setText(`${props?.room?.room?.guest_name || props?.room?.room_summary?.guest_name} is ready`);
    } else {
      setText(`${props?.room?.room?.guest_name || props?.room?.room_summary?.guest_name} has not yet joined.`);
    }
  }, [props.room.guest_ready]);

  return (
    <Fade in={true} timeout={{ enter: 500, exit: 0 }}>
      <PreJoinContiner>
        <LightTitle variant="h4" align="center">
          Waiting to start...
        </LightTitle>
        <Typography>{text}</Typography>
        <PreConsultActionButton
          type="submit"
          color="secondary"
          variant="contained"
          disabled={!props.room.guest_ready}
          onClick={handleStart}
        >
          Start meeting
        </PreConsultActionButton>
      </PreJoinContiner>
    </Fade>
  );
};
