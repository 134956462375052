import { store } from '../index';

export const getPermissions = () => {
  navigator.mediaDevices
    .enumerateDevices()
    .then(devices => devices.every(device => !(device.deviceId && device.label)))
    .then(shouldAskForMediaPermission => {
      console.log(shouldAskForMediaPermission);
      if (shouldAskForMediaPermission) {
        store.setPermissionsStep('prompt');
      } else {
        store.setRequestPermissions(false);
      }
    });
};

export const getDevices = () => {
  navigator.mediaDevices.enumerateDevices();
};
