import { WEBSOCKET_URL } from '../constants';
import { store } from '../index';

const MAX_RECONNECTS = 50;
export const ROOM_STATUS = 'ROOM_STATUS';
export const CONNECTED_FROM_ANOTHER_DEVICE = 'CONNECTED_FROM_ANOTHER_DEVICE';
const SOCKET_CONNECTING = 0;
const SOCKET_OPEN = 1;

export default class Socket {
  private socket: any;
  private connectionChangeInterval = 0;
  private reconnectCount = 0;

  constructor() {
    this.socket = null;
  }

  private onConnectionChange(isConnected: boolean) {
    store.setConnected(isConnected);
    if (isConnected && this.socket) {
      const poll = () => this.sendMessage({ action: 'status' });

      clearInterval(this.connectionChangeInterval);
      this.connectionChangeInterval = <any>setInterval(poll, 15 * 1000);
      poll();
    } else {
      clearInterval(this.connectionChangeInterval);
    }
  }

  private onIncomingMessage = (message: any) => {
    if (!message.type) return;

    if (message.type === ROOM_STATUS) {
      store.setRoom(message.payload);
    } else if (message.type === CONNECTED_FROM_ANOTHER_DEVICE) {
      store.setRoom(null);
      store.setConnectedFromAnotherDevice(true);
      store.setShouldConnect(false);
      if (this.socket) {
        this.disconnect();
      }
    }
  };

  private onDisconnect() {
    setTimeout(() => {
      if (store.shouldConnect && this.reconnectCount < MAX_RECONNECTS) {
        this.reconnectCount++;
        this.connect(`${WEBSOCKET_URL}`, store.token);
      }
    }, 5 * 1000);
  }

  public connect = (url: string, token: string) => {
    if (this.socket && (this.socket.readyState === SOCKET_OPEN || this.socket.readyState === SOCKET_CONNECTING)) {
      return;
    }

    this.socket = new WebSocket(url, token);

    this.socket.onopen = (event: Event) => {
      this.onConnectionChange(true);
    };

    this.socket.onclose = (event: Event) => {
      this.onConnectionChange(false);
      this.onDisconnect();
    };

    this.socket.onmessage = (event: MessageEvent) => {
      this.onIncomingMessage(JSON.parse(event.data));
    };
  };

  public sendMessage = (message: object) => {
    if (this.socket.readyState === SOCKET_OPEN) {
      this.socket.send(JSON.stringify(message));
    } else {
      console.error('Cannot emit socket messages. WebSocket not connected.');
    }
  };

  public disconnect = () => this.socket.close();
}
