import React from 'react';

import useSound from 'use-sound';

import { styled } from '@material-ui/core/styles';
import { Typography, Slide } from '@material-ui/core';
import PreConsultActionButton from './PreConsultActionButton';

const PreJoinContiner = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyItems: 'center',
  width: '100%',
  height: '100%',
});

const LightTitle = styled(Typography)({
  paddingTop: 15,
  paddingBottom: 15,
});

type OnReadyFunction = () => void;

const getText = (room: any) => {
  if (room === null) return;

  if (room.participant_type === 'host') {
    if (room.guest_ready) {
      return `${room.room.guest_name} is ready`;
    } else {
      return `${room.room.guest_name} has not yet joined`;
    }
  }

  if (room.participant_type === 'guest') {
    if (room.host_ready) {
      return `Your consult is with ${room.room.host_name}`;
    } else {
      return `Your consult is with ${room.room.host_name}`;
    }
  }

  return;
};

export default (props: { onReady: OnReadyFunction; room: object }) => {
  return (
    <Slide in={true} direction="down" timeout={{ enter: 500, exit: 0 }}>
      <PreJoinContiner>
        <LightTitle variant="h4" align="center">
          Ready to join?
        </LightTitle>
        <Typography>{getText(props.room)}</Typography>
        <PreConsultActionButton type="submit" color="primary" variant="contained" onClick={props.onReady}>
          I am ready
        </PreConsultActionButton>
      </PreJoinContiner>
    </Slide>
  );
};
