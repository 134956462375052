import { Grid, Hidden, makeStyles, Theme, Typography } from '@material-ui/core';
import React from 'react';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import EndCallButton from '../Buttons/EndCallButton/EndCallButton';
import Menu from '../MenuBar/Menu/Menu';
import VideoQualityButton from '../MenuBar/VideoQualityButton';
import FlipCameraButton from '../MenuBar/FlipCameraButton/FlipCameraButton';
import ToggleFullscreenButton from '../MenuBar/ToggleFullScreenButton/ToggleFullScreenButton';
import useRoomState from '../../hooks/useRoomState/useRoomState';
import { store } from '../../index';
import AppBar from '@material-ui/core/AppBar';
import { observer } from 'mobx-react-lite';

const useStyles = makeStyles((theme: Theme) => ({
  mainContainer: {
    backgroundColor: theme.palette.background.paper,
    height: `${theme.mobileTopBarHeight}px`,
    marginBottom: '10px',
    paddingLeft: '1em',
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      justifyContent: 'center',
    },
  },
  container: {
    background: 'white',
    paddingLeft: '1em',
    display: 'none',
    height: `${theme.mobileTopBarHeight}px`,
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
    },
  },
  endCallButton: {
    height: '28px',
    fontSize: '0.85rem',
    padding: '0 0.6em',
  },
  settingsButton: {
    [theme.breakpoints.down('sm')]: {
      height: '28px',
      minWidth: '28px',
      border: '1px solid rgb(136, 140, 142)',
      padding: 0,
      margin: '0 1em',
    },
  },
  displayName: {
    margin: '1.1em 0.6em',
    color: theme.palette.text.primary,
  },
  mobileButton: {
    padding: '0.8em 0',
    margin: 0,
  },
}));

function MobileTopMenuBar() {
  const classes = useStyles();
  const roomState = useRoomState();
  const { isConnecting } = useVideoContext();
  const renderFullScreenButton = /Android|Macintosh|Windows|Linux/.test(navigator.userAgent);

  return (
    <AppBar className={classes.mainContainer} position="static">
      <Grid container direction="row" justifyContent="space-between" alignItems="center">
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {roomState == 'disconnected' && (
            <>
              {store.tenantLogo ? (
                <img src={store.tenantLogo} style={{ height: '40px', marginLeft: '5px' }} alt="Logo" />
              ) : null}
              <Typography className={classes.displayName}>
                {store.tenantProjectName ? store.tenantProjectName : null}
              </Typography>
            </>
          )}
          {roomState == 'connected' && (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <EndCallButton className={classes.endCallButton} />
            </div>
          )}
        </div>
        <Hidden smUp>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {!isConnecting && <VideoQualityButton />}
            {!isConnecting && <FlipCameraButton />}
            {renderFullScreenButton && <ToggleFullscreenButton />}
            {roomState == 'connected' && !isConnecting && <Menu buttonClassName={classes.settingsButton} />}
          </div>
        </Hidden>
      </Grid>
    </AppBar>
  );
}

export default observer(MobileTopMenuBar);
